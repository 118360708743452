import { createAxiosByinterceptors } from "@/api/request";
import url from "@/constant/url";
const vueAppEnv = process.env.VUE_APP_ENV;

const request = createAxiosByinterceptors({
  baseURL: (url as any)[vueAppEnv as any].home,
});

//新增工资条
export const salarySlipAdd = (data: any): Promise<any> =>
  request.post("/api/salary_slip", data);

//查询工资条
export const salarySlipList = (params: any): Promise<any> =>
  request.get("/api/salary_slip", { params });

//下载Excel模板
export const salarySlipExcelTemplate = (id: any): Promise<any> =>
  request.get("/api/salary_slip/" + id + "/excel_template");

//上传Excel模板
export const salarySlipExcelTemplateImport = (
  id: any,
  data: any
): Promise<any> =>
  request.post("/api/salary_slip/" + id + "/excel_template", data);

//归档
export const salarySlipArchive = (id: any): Promise<any> =>
  request.put("/api/salary_slip/" + id + "/archive");

//详细
export const salarySlipDetail = (id: string, params: any): Promise<any> =>
  request.get("/api/salary_slip/" + id + "/detail", { params });

//重新计算
export const salarySlipCalc = (id: string): Promise<any> =>
  request.post("/api/salary_slip/" + id + "/calc");

//导出工资条
export const salarySlipExport = (id: any): Promise<any> =>
  request.get("/api/salary_slip/" + id + "/export");

//修改工资详细
export const detailUpdate = (id: any, detailId: any, data: any): Promise<any> =>
  request.put("/api/salary_slip/" + id + "/detail/" + detailId, data);

//新增工资条
export const salarySlipDelete = (id: any): Promise<any> =>
  request.delete("/api/salary_slip/" + id);
