import { createAxiosByinterceptors } from "@/api/request";
import url from "@/constant/url";
const vueAppEnv = process.env.VUE_APP_ENV;

const request = createAxiosByinterceptors({
  baseURL: (url as any)[vueAppEnv as any].home,
});

//获取用户组
export const groupDetails = (id: any): Promise<any> =>
  request.get("/api/user_group/" + id);

//新增用户组
export const groupAdd = (data: any): Promise<any> =>
  request.post("/api/user_group", data);

//修改用户组
export const groupUpdate = (id: string, data: any): Promise<any> =>
  request.put("/api/user_group/" + id, data);

//用户组列表
export const groupList = (params: any): Promise<any> =>
  request.get("/api/user_group", { params });

//用户组删除
export const groupDelete = (id: any): Promise<any> =>
  request.delete("/api/user_group/" + id);
