import { createAxiosByinterceptors } from "@/api/request";
import url from "@/constant/url";
const vueAppEnv = process.env.VUE_APP_ENV;

const request = createAxiosByinterceptors({
  baseURL: (url as any)[vueAppEnv as any].home,
});

//薪酬结构额外字段
//新增字段
export const structureAdd = (data: any): Promise<any> =>
  request.post("/api/salary_base", data);

//修改字段
export const structureUpdate = (id: string, data: any): Promise<any> =>
  request.put("/api/salary_base/" + id, data);

//字段列表
export const structureList = (params: any): Promise<any> =>
  request.get("/api/salary_base", { params });

//获取所有字段列表
export const structureQuery = (params: any): Promise<any> => {
  const params_ = Object.assign({}, params, { select: "*" });
  // eslint-disable-next-line no-self-assign
  params_.group_id = "eq." + params_.group_id; //eq等于，like模糊
  return request.get("/api/salary_base/query", { params: params_ });
};

//字段删除
export const structureDelete = (id: any): Promise<any> =>
  request.delete("/api/salary_base/" + id);

//获取字段信息
export const structureDetails = (id: any): Promise<any> =>
  request.get("/api/salary_base/" + id);

//获取所有薪酬结构
export const structure = (params: any): Promise<any> =>
  request.get("/api/salary_base", { params });
