

























































































































































import Vue from "vue";
import { detailUpdate } from "@/api/salary/relation";
export default Vue.extend({
  props: ["isEditFlag", "baseEditInfo", "extraHeader", "salaryBase"],
  data() {
    return {
      editFormInfo: {
        id: null,
        slipId: null,
        memberId: null,
        salaryPayable: 0,
        actualSalary: 0,
        fields: {}, //额外字段
      },
      editFormInfoRules: {},
      groupName: "",
      seletYaer: "",
      selectMonth: "",
      checkedFields: [],
      //字典
      groupTypeList: [],
      filedsList: [],

      fields: [],
    };
  },
  computed: {
    fieldsShow() {
      return (row: any, type: string) => {
        if (type == "field") {
          if (this.fields.findIndex((c: string) => c == row) != -1) {
            return true;
          } else {
            return false;
          }
        } else {
          let fields_ = row.data;
          if (
            fields_.findIndex(
              (c: string) => this.fields.findIndex((d: string) => d == c) != -1
            ) == -1
          ) {
            return false;
          } else {
            return true;
          }
        }
      };
    },
  },
  mounted() {
    this.editFormInfo.id = this.baseEditInfo.id;
    this.editFormInfo.slipId = this.baseEditInfo.slipId;
    this.editFormInfo.memberId = this.baseEditInfo.memberId;
    this.editFormInfo.salaryPayable = this.baseEditInfo.salaryPayable;
    this.editFormInfo.actualSalary = this.baseEditInfo.actualSalary;
    let fields_: any = Object.keys(this.baseEditInfo.fields);
    this.fields = fields_;
    fields_.forEach((c: any) => {
      this.$set(this.editFormInfo.fields, c, this.baseEditInfo.fields[c]);
    });
  },

  methods: {
    saveFn: async function () {
      let flag = true;
      (this.$refs.editFormInfoForm as any).validate((valid: boolean) => {
        if (!valid) {
          flag = false;
        }
      });
      if (!flag) {
        return;
      }

      let fields_ = {};
      let obj = this.$deepCopy(this.editFormInfo);
      // obj.fields.forEach((c: any) => {
      //   fields_[c.name] = c.value;
      // });
      obj.fields = JSON.stringify(obj.fields);
      detailUpdate(obj.slipId, obj.id, obj).then(() => {
        this.$message.success("保存成功");
        this.closeFn(true);
      });
    },
    closeFn(flag = false) {
      this.$emit("closeFn", flag);
    },
    getDepartment(depart: any, flag = true) {
      let department_ = "";
      if (flag) {
        department_ = this.showDepartment(depart);
        if (department_.length > 0) {
          department_ = department_.substr(0, department_.length - 1);
        }
      } else {
        department_ = depart?.name || "";
      }

      return department_;
    },
    showDepartment(depart: any) {
      let department_ = "";
      if (depart && Object.keys(depart).length > 0) {
        department_ = depart.name + "/" + department_;
        department_ = this.showDepartment(depart.parent) + department_;
      }

      return department_;
    },
  },
});
