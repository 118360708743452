import { createAxiosByinterceptors } from "@/api/request";
import url from "@/constant/url";
const vueAppEnv = process.env.VUE_APP_ENV;

const request = createAxiosByinterceptors({
  baseURL: (url as any)[vueAppEnv as any].home,
});

//获取字段信息
export const fieldDetails = (id: any): Promise<any> =>
  request.get("/api/salary_field/" + id);

//字段验证
export const fieldValidate = (data: any): Promise<any> =>
  request.post("/api/salary_field/validate", data);

//新增字段
export const fieldAdd = (data: any): Promise<any> =>
  request.post("/api/salary_field", data);

//修改字段
export const fieldUpdate = (id: string, data: any): Promise<any> =>
  request.put("/api/salary_field/" + id, data);

//字段列表
export const fieldList = (params: any): Promise<any> =>
  request.get("/api/salary_field", { params });

//字段删除
export const fieldDelete = (id: any): Promise<any> =>
  request.delete("/api/salary_field/" + id);

//获取所有字段列表
export const fieldQuery = (params: any): Promise<any> => {
  const params_ = Object.assign({}, params, { select: "*" });
  // eslint-disable-next-line no-self-assign
  params_.group_id = "eq." + params_.group_id; //eq等于，like模糊
  params_.orderBy = "sort_num";
  params_.desc = true;
  return request.get("/api/salary_field/query", { params: params_ });
};

//获取所有可依赖字段
export const fieldDatasource = (params: any): Promise<any> => {
  return request.get("/api/salary_field/datasource");
};

//查询增减项排序号
export const fieldSortSearch = (groupId: any): Promise<any> => {
  return request.get("/api/salary_field/assetting", {
    params: { groupId: groupId },
  });
};

//修改增减项排序号
export const fieldSortUpdate = (groupId: any, data: any): Promise<any> => {
  return request.put("/api/salary_field/assetting?groupId=" + groupId, data);
};
