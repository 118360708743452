









































































































































































import Vue from "vue";
import {
  salarySlipList,
  salarySlipExcelTemplate,
  salarySlipArchive,
  salarySlipDelete,
} from "@/api/salary/relation";
import editForm from "./editForm.vue";
import detailForm from "./detailForm.vue";
import { groupList } from "@/api/user/group";
// import miniUploadFileForm from "@/components/common/miniUploadFileForm.vue";
import { fieldList, fieldDelete, fieldQuery } from "@/api/salary/field";
export default Vue.extend({
  components: { editForm, detailForm },
  data() {
    return {
      searchObj: {
        isMap: true,
      },
      pageObj: {
        pageSize: 10,
        pageNum: 1,
      },
      pageSizesList: [10, 15, 20, 50, 100],
      tableTotal: 0,
      tableData: [],
      editFormVisible: "",
      isEditFlag: false,
      baseEditInfo: {},
      extraHeaderList: [],
      //字典
      groupTypeList: [],
      filesSettingList: [],
    };
  },
  mounted() {
    groupList({}).then((res: any) => {
      this.groupTypeList = res.content.content.records || [];
    });
    fieldQuery({ group_id: "" }).then((res: any) => {
      this.filesSettingList = res.content.records || [];
    });
    this.getList();
  },
  methods: {
    //common
    pageNumChange(val: any) {
      this.pageObj.pageNum = val;
      this.getList();
    },
    pageSizeChange(val: any) {
      this.pageObj.pageNum = 1;
      this.pageObj.pageSize = val;
      this.getList();
    },
    getList() {
      this.getListData();
    },
    expoortFn(row: any) {
      if (row.status == "已归档") {
        this.$message.warning("数据已归档");
        return;
      }

      salarySlipExcelTemplate(row.id).then((res) => {
        this.saveAs(res, row.name);
      });
    },
    fileUploadSuccess() {
      this.getList();
    },
    saveAs(data, name) {
      const blob = new Blob([data], { type: "application/vnd.ms-excel" }); //创建一个新的 Blob 对象
      const URL = window.URL || window.webkitURL;
      const url = URL.createObjectURL(blob); //  把一个blob对象转化为一个Blob URL，创建下载链接
      const downloadLink = document.createElement("a"); // 生成一个a标签
      downloadLink.href = url;
      downloadLink.download = name + ".xlsx"; // // dowload属性指定下载后文件名
      document.body.appendChild(downloadLink); //将a标签添加到body中
      downloadLink.click(); // 点击下载
      document.body.removeChild(downloadLink); // 下载完成后移除元素
      window.URL.revokeObjectURL(url); // 释放掉blob对象
    },
    archiveFn(row: any) {
      if (row.status == "已归档") {
        this.$message.warning("数据已归档");
        return;
      }
      salarySlipArchive(row.id).then(() => {
        this.getList();
      });
    },
    listEditFn(row: any, type: string) {
      this.editFormVisible = "detail";
      this.isEditFlag = type == "see" ? false : true;
      this.baseEditInfo = row;
    },
    listDeleteFn(row: any) {
      if (row.status == "已归档") {
        this.$message.warning("数据已归档,不可删除!");
        return;
      }
      this.$confirm("是否删除本条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        salarySlipDelete(row.id).then(() => {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.getList();
        });
      });
    },
    addFn() {
      this.editFormVisible = "edit";
      this.baseEditInfo = this.$options.data().baseEditInfo;
      this.isEditFlag = true;
    },
    deleteFn() {
      console.log("删除");
    },
    closeFn(flag = false) {
      this.editFormVisible = "";
      if (flag) {
        this.getList();
      }
    },
    //业务
    getListData() {
      const obj = Object.assign({}, this.searchObj, this.pageObj);
      salarySlipList(obj).then((res: any) => {
        this.tableData = res.content.records || [];
        this.tableTotal = res.content.total || 0;

        this.tableData.forEach((c, idx) => {
          if (c.extraHeader) {
            c.extraHeader = JSON.parse(c.extraHeader);
            if (idx == 0) {
              this.extraHeaderList = Object.keys(c.extraHeader);
            }
          }
        });

        this.$nextTick(() => {
          this.$refs.tableDiv.doLayout();
        });
      });
    },
  },
});
