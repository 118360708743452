




































































































import Vue from "vue";
import { salarySlipAdd } from "@/api/salary/relation";
import { groupList } from "@/api/user/group";
import { fieldQuery } from "@/api/salary/field";
export default Vue.extend({
  props: ["isEditFlag", "baseEditInfo"],
  data() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let _this = this;
    let validatorName = (rule, value, callback) => {
      if (_this.editFormInfo.groupId === "" || !_this.editFormInfo.groupId) {
        callback("请选择分组");
      } else if (_this.seletYaer === "" || !_this.seletYaer) {
        callback("请选择年");
      } else if (_this.selectMonth === "" || !_this.selectMonth) {
        callback("请选择月");
      } else {
        callback();
      }
    };
    return {
      editFormInfo: {
        name: null, //字段名称
        groupId: null, //适用分组
        fields: [], //额外字段
        dateTag: null,
      },
      editFormInfoRules: {
        groupId: [{ required: true, validator: validatorName }],
        dateTag: [{ required: true, message: '请选择考勤数据',trigger:'change' }],
      },
      groupName: "",
      seletYaer: "",
      selectMonth: "",
      checkedFields: [],
      //字典
      groupTypeList: [],
      filedsList: [],
    };
  },
  mounted() {
    groupList({}).then((res: any) => {
      this.groupTypeList = res.content.content.records || [];
    });
  },
  methods: {
    groupIdChangeFn(val) {
      if (val) {
        fieldQuery({ group_id: val }).then((res: any) => {
          this.filedsList = res.content.records || [];
          this.checkedFields = this.filedsList
            .filter((c) => !!c.enableByDefault)
            .map((c) => {
              return c.id;
            });
        });
        this.groupName = this.groupTypeList.find((c) => c.id == val).name;
      } else {
        this.filedsList = [];
        this.checkedFields = [];
        this.groupName = "";
      }
    },
    saveFn: async function () {
      let flag = true;
      (this.$refs.editFormInfoForm as any).validate((valid: boolean) => {
        if (!valid) {
          flag = false;
        }
      });
      if (!flag) {
        return;
      }

      let obj = this.$deepCopy(this.editFormInfo);
      obj.name = `[${this.groupName || ""}]${this.seletYaer}年${
        this.selectMonth
      }月工资`;
      if (this.checkedFields.length > 0) {
        obj.fields = this.checkedFields.map((c: string) => {
          return {
            extraDefineId: c,
          };
        });
      }

      salarySlipAdd(obj).then(() => {
        this.$message.success("保存成功");
        this.closeFn(true);
      });
    },
    closeFn(flag = false) {
      this.$emit("closeFn", flag);
    },
  },
});
