

















































































































































































































































































import Vue from "vue";
import { salarySlipDetail } from "@/api/salary/relation";
import miniUploadFileForm from "@/components/common/miniUploadFileForm.vue";
import {
  salarySlipExcelTemplate,
  salarySlipCalc,
  salarySlipExport,
} from "@/api/salary/relation";
import editForm from "./detailsEditForm.vue";
import { structure } from "@/api/salary/structure";

import _ from "lodash";
export default Vue.extend({
  components: { miniUploadFileForm, editForm },
  props: ["isEditFlag", "baseEditInfo", "filesSettingList"],
  data() {
    return {
      pageObj: {
        pageSize: 10,
        pageNum: 1,
      },
      searchObj: {
        key: "",
      },
      pageSizesList: [10, 15, 20, 50, 100],
      tableTotal: 0,
      tableData: [],
      editFormVisible: "",
      deBaseEditInfo: {},
      extraHeader: [],
      salaryBase: [],

      timer: null,
    };
  },
  watch: {
    // "searchObj.key"(val) {},
  },
  computed: {
    getWidth() {
      return (name: string) => {
        if (
          name &&
          this.baseEditInfo.groupId &&
          this.filesSettingList &&
          this.filesSettingList.length > 0
        ) {
          if (
            this.filesSettingList.findIndex(
              (c) =>
                c.name == name && c.userGroup.id == this.baseEditInfo.groupId
            ) == -1
          ) {
            return 120;
          } else {
            return this.filesSettingList.find(
              (c) =>
                c.name == name && c.userGroup.id == this.baseEditInfo.groupId
            ).displayWidth;
          }
        } else {
          return 120;
        }
      };
    },
  },
  async mounted() {
    await structure({ groupId: this.baseEditInfo.groupId }).then((res) => {
      res.content.records.forEach((c) => {
        this.salaryBase.push({
          defineId: c.id,
          name: c.name,
          value: c.defaultValue,
        });
      });
    });

    if (this.baseEditInfo.extraHeader) {
      let header_ = this.baseEditInfo.extraHeader;
      let keys_ = Object.keys(header_);
      if (keys_.indexOf("all") != -1) {
        let allKeys = header_["all"];
        keys_.forEach((c) => {
          if (c == "all") {
            allKeys.forEach((d) => {
              if (keys_.indexOf(d) == -1) {
                this.extraHeader.push({ name: d, type: "field" });
              } else {
                this.extraHeader.push({
                  name: d,
                  data: header_[d],
                  type: "fields",
                });
              }
            });
            // header_[c].forEach((d) => {
            //   if (keys_.indexOf(d) == -1) {
            //     this.extraHeader.push({ name: d, type: "field" });
            //   } else {
            //     this.extraHeader.push({
            //       name: d,
            //       data: header_[d],
            //       type: "fields",
            //     });
            //   }
            // });
          } else if (c != "all" && allKeys.indexOf(c) == -1) {
            this.extraHeader.push({
              name: c,
              data: header_[c],
              type: "fields",
            });
          }
        });
      } else {
        keys_.forEach((c) => {
          this.extraHeader.push({
            name: c,
            data: header_[c],
            type: "fields",
          });
        });
      }
    }
    if (this.baseEditInfo.status == "正在生成工资条") {
      this.setTimer();
    }
    this.getList();
  },
  beforeDestroy() {
    this.removeTimer();
  },
  methods: {
    handleDebounce: _.debounce(function () {
      this.getList();
    }, 800),
    setTimer() {
      this.removeTimer();
      this.timer = setInterval(() => {
        this.pageObj.pageNum = 1;
        this.getList();
      }, 3000);
    },
    removeTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    countAaginFn(row: any) {
      if (row.status == "已归档") {
        this.$message.warning("数据已归档");
        return;
      }

      salarySlipCalc(row.id).then((res) => {
        this.$message.success(res.content);
        // this.$message.success("正在重新计算工资条");
      });

      this.setTimer();
    },
    expoortFn(row: any) {
      if (row.status == "已归档") {
        this.$message.warning("数据已归档");
        return;
      }

      salarySlipExcelTemplate(row.id).then((res) => {
        this.saveAs(res, row.name);
      });
    },
    expoortGzFn(row: any) {
      salarySlipExport(row.id).then((res) => {
        this.saveAs(res, row.name);
      });
    },
    saveAs(data, name) {
      const blob = new Blob([data], { type: "application/vnd.ms-excel" }); //创建一个新的 Blob 对象
      const URL = window.URL || window.webkitURL;
      const url = URL.createObjectURL(blob); //  把一个blob对象转化为一个Blob URL，创建下载链接
      const downloadLink = document.createElement("a"); // 生成一个a标签
      downloadLink.href = url;
      downloadLink.download = name + ".xlsx"; // // dowload属性指定下载后文件名
      document.body.appendChild(downloadLink); //将a标签添加到body中
      downloadLink.click(); // 点击下载
      document.body.removeChild(downloadLink); // 下载完成后移除元素
      window.URL.revokeObjectURL(url); // 释放掉blob对象
    },
    //common
    pageNumChange(val: any) {
      this.pageObj.pageNum = val;
      this.getList();
    },
    pageSizeChange(val: any) {
      this.pageObj.pageNum = 1;
      this.pageObj.pageSize = val;
      this.getList();
    },
    getList() {
      this.getListData();
    },
    listEditFn(row: any, type: string) {
      this.editFormVisible = "detail";
      this.deBaseEditInfo = row;
    },
    //业务
    getListData() {
      salarySlipDetail(this.baseEditInfo.id, this.searchObj).then((res) => {
        (res.content.records || []).forEach((c) => {
          c.fields = JSON.parse(c.fields);
        });
        this.tableData = res.content?.records || [];
        this.tableTotal = res.content?.total || 0;
        this.removeTimer();

        this.$nextTick(() => {
          this.$refs.tableDiv.doLayout();
        });
      });
    },
    closeFn(flag = false) {
      this.$emit("closeFn", true);
    },
    getDepartment(depart: any, flag = true) {
      let department_ = "";
      if (flag) {
        department_ = this.showDepartment(depart);
        if (department_.length > 0) {
          department_ = department_.substr(0, department_.length - 1);
        }
      } else {
        department_ = depart?.name || "";
      }

      return department_;
    },
    showDepartment(depart: any) {
      let department_ = "";
      if (depart && Object.keys(depart).length > 0) {
        department_ = depart.name + "/" + department_;
        department_ = this.showDepartment(depart.parent) + department_;
      }

      return department_;
    },
    closeEditFn(flag = false) {
      this.editFormVisible = "";
      if (flag) {
        this.getList();
      }
    },
    searchFn() {
      this.handleDebounce();
    },
  },
});
