<template>
  <div class="miniUploadFileForm">
    <div class="uploadFileBtn">
      <label class="file-select">
        <input
          type="file"
          :disabled="disabled"
          ref="fileUploadForm"
          @change="handleFileChange"
        />

        <slot>
          <i class="el-icon-circle-plus-outline"></i>
          <slot name="label"></slot>
        </slot>
      </label>
    </div>
    <div class="shadeBox" v-if="uploadImageFlag">
      <div class="progressBox">
        <div
          class="progress"
          :style="[
            {
              width: uploadProgress + '%',
            },
          ]"
        ></div>
      </div>
      <div class="labelBox">
        上传文件中
        <span>{{ uploadProgress }}%</span> &nbsp;
      </div>
    </div>
  </div>
</template>
<script>
import authModule from "@/store/modules/auth";
const myWindow = require("@/../public/config");
import axios from "axios";
export default {
  components: {},
  props: {
    acceptType: {
      type: Array,
      default: () => {
        return ["img", "video", "audio", "document"];
      },
    },
    imgType: {
      type: Number,
      default: 1,
    },
    videoType: {
      type: Number,
      default: 3,
    },
    audioType: {
      type: Number,
      default: 2,
    },
    txtType: {
      type: Number,
      default: 4,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: "",
    },
  },
  computed: {},
  data() {
    return {
      tableAllList: [],
      imgExit: ["jpeg", "jpg", "png"],
      videoExit: ["mov", "mp4"],
      audioExit: ["mp3"],
      documentExts: [
        "docx",
        "doc",
        "pptx",
        "ppt",
        "pdf",
        "xml",
        "html",
        "json",
        "txt",
        "xlsx",
        "xls",
      ],
      fileType: "",
      baseUrl:
        process.env.NODE_ENV == "development"
          ? myWindow.configObj.VUE_APP_BASE_API
          : "",
      errImg: 'this.src="' + require("@/assets/common/errimg.png") + '"',
      dialogVisible: false,
      embedUrl: "",

      uploadFileSizeMax: 1000, //最大文件总和
      sizeUnit: 1024 * 1024, //MB
      uploadImageFlag: false,
      uploadProgress: 0,

      uploadUrl:
        (process.env.NODE_ENV == "development"
          ? myWindow.configObj.VUE_APP_BASE_API
          : "") + (this.url ? this.url : "/api/file/"),
    };
  },
  provide() {
    return {};
  },
  watch: {},
  methods: {
    //上传按钮事件
    handleFileChange(file, fileLists) {
      //本地程序需解析文件获取本地文件绝对路径
      this.beforeUpload(this.$refs.fileUploadForm.files);
      this.$refs.fileUploadForm.value = "";
    },
    beforeUpload(files) {
      let sizesTotal = 0;
      for (let i = 0; i < files.length; i++) {
        sizesTotal += files[i].size;
      }

      if (sizesTotal > this.uploadFileSizeMax * this.sizeUnit) {
        this.$message.warning("已超出文件大小限制(1000MB)!");
        return;
      }
      let exits = [];

      if (this.acceptType.findIndex((c) => c == "img") != -1) {
        exits = exits.concat(this.imgExit);
      }

      if (this.acceptType.findIndex((c) => c == "video") != -1) {
        exits = exits.concat(this.videoExit);
      }

      if (this.acceptType.findIndex((c) => c == "audio") != -1) {
        exits = exits.concat(this.audioExit);
      }

      if (this.acceptType.findIndex((c) => c == "document") != -1) {
        exits = exits.concat(this.documentExts);
      }
      for (let i = 0; i < files.length; i++) {
        if (
          exits.findIndex(
            (c) =>
              c ==
              files[i].name
                .substring(files[i].name.lastIndexOf(".") + 1)
                .toLowerCase()
          ) != -1
        ) {
          this.tableAllList.push({
            name: files[i].name,
            src: window.URL.createObjectURL(files[i]),
            fileCreateTime: this.$showTime(files[i].lastModifiedDate, true),
            exit: files[i].name
              .substring(files[i].name.lastIndexOf(".") + 1)
              .toLowerCase(),
            file: files[i],
            type:
              files[i].type.indexOf("image") != -1
                ? this.imgType
                : files[i].type.indexOf("video") != -1
                ? this.videoType
                : files[i].type.indexOf("audio") != -1
                ? this.audioType
                : this.txtType,
            uuid: this.tableAllList.length,
            size: files[i].size,
          });
        } else {
          this.$message.warning(
            "暂不支持" +
              files[i].name.substring(files[i].name.lastIndexOf(".") + 1) +
              "格式文件上传"
          );
        }
        if (this.tableAllList.length > 0) {
          this.uploadExcel(this.tableAllList);
        }
      }
    },
    async uploadExcel(files) {
      this.uploadImageFlag = false;
      const token = await authModule.getToken();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
          token: token,
        },
        onUploadProgress: (progressEvent) => {
          this.uploadProgress = parseFloat(
            (progressEvent.loaded / progressEvent.total) * 100
          ).toFixed(1);
        },
      };
      let formData = new FormData();
      files.forEach((item) => {
        formData.append("file", item.file);
      });

      axios
        .post(this.uploadUrl, formData, config)
        .then((res) => {
          if (res.data.status != "SUCCESS") {
            this.$message.warning(res.data.content);
            this.uploadImageFlag = false;
          } else {
            this.$message.success(res.data.content);
            this.uploadImageFlag = false;

            this.$emit("fileUploadSuccess");
          }
          this.tableAllList = [];
        })
        .catch(() => {
          this.$message.warning("导入失败");
          this.uploadImageFlag = false;
          this.tableAllList = [];
        });
    },
  },
};
</script>
<style scoped lang="less">
.miniUploadFileForm {
  display: flex;
  justify-content: center;
  .uploadFileBtn {
    text-align: center;
    color: #078d77;
    font-size: 1.45vw;
    cursor: pointer;
    float: left;
    &:hover {
      color: #30d6ba;
    }
    .file-select {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .file-select > input[type="file"] {
      display: none;
    }
  }
  .shadeBox {
    flex: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    .progressBox {
      width: 33%;
      background: #838383;
      height: 1vh;
      border-radius: 0.5vh;
      .progress {
        background: #008375;
        height: 1vh;
        border-radius: 0.5vh;
      }
    }
    .labelBox {
      margin-left: 1vw;
      display: flex;
      align-items: center;
      font-weight: bold;
      .lbSl {
        font-size: 0.5vw;
        font-weight: 500;
      }
    }
  }
}
</style>
